<template>
  <div>
    <b-card>
      <div class="row mb-3">
        <div class="col-12 d-flex">
          <span class="h2 mr-auto text-secondary"> Nouveau bénéficiaire</span>

          <div>
            <modal-actions @close="$router.go(-1)" />
          </div>
        </div>
      </div>
      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <span class="h3 text-secondary">Informations générales</span>
          <div>
            <b-button
              @click="createItem"
              type="submit"
              pill
              :disabled="submitingForm"
            >
              <b-spinner
                small
                v-if="submitingForm"
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
          </div>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />
        <div class="row">
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="4"
              label="Bénificaire :"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="text"
                placeholder="Bénificaire"
                :class="{
                  'is-invalid':
                    $v.newBenf.name.$error && $v.newBenf.name.$dirty,
                }"
                v-model="newBenf.name"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newBenf.name.$dirty"
              >
                {{
                  !$v.newBenf.name.required
                    ? "champ obligatoire"
                    : !$v.newBenf.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newBenf.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label="Volume :"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="number"
                placeholder="Volume"
                min="0"
                :class="{
                  'is-invalid':
                    $v.newBenf.volume.$error && $v.newBenf.volume.$dirty,
                }"
                v-model="newBenf.volume"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newBenf.volume.$dirty"
              >
                {{ !$v.newBenf.volume.required ? "champ obligatoire" : "" }}
              </span>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6" id="add-page">
            <b-form-group
              label-cols="4"
              label="Type :"
              label-class="font-weight-bold"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newBenf.type.$error && $v.newBenf.type.$dirty,
                }"
              >
                <v-select
                  class="bg-transparent"
                  placeholder="Type"
                  v-model="newBenf.type"
                  :options="['Externe', 'Interne']"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newBenf.type.$dirty"
              >
                {{ !$v.newBenf.type.required ? "champ obligatoire" : "" }}
              </span>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import { minLength, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapGetters } from "vuex"; //validation
export default {
  components: {
    ModalActions,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    newBenf: {
      name: "",
      type: "",
      volume: "",
    },
  }),
  validations: {
    newBenf: {
      name: {
        required,
        minLength: minLength(5),
      },
      type: {
        required,
      },
      volume: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters("beneficiare", ["Beneficiare"]),
  },
  methods: {
    createItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.submitingForm = true;
        this.$store
          .dispatch("beneficiare/createBeneficiare", {
            name: this.newBenf.name,
            type: this.newBenf.type,
            volume: parseInt(this.newBenf.volume),
          })
          .then(() => {
            this.submitingForm = false;
            Swal.fire({
              title: "Le bénéficiare est bien créé !",
              type: "success",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.value) {
                this.$router.push({
                  name: "show-payee",
                  params: {
                    slug: this.Beneficiare.slug || "abcd",
                    id: this.Beneficiare.id,
                    fromCreation: true,
                  },
                }); //redirect to the new task
              }
            });
          });
      }
    },
  },
};
</script>

<style></style>
