var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 d-flex"},[_c('span',{staticClass:"h2 mr-auto text-secondary"},[_vm._v(" Nouveau bénéficiaire")]),_c('div',[_c('modal-actions',{on:{"close":function($event){return _vm.$router.go(-1)}}})],1)])]),_c('div',{staticClass:"shdow"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center"},[_c('span',{staticClass:"h3 text-secondary"},[_vm._v("Informations générales")]),_c('div',[_c('b-button',{attrs:{"type":"submit","pill":"","disabled":_vm.submitingForm},on:{"click":_vm.createItem}},[(_vm.submitingForm)?_c('b-spinner',{staticClass:"text-white",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(" Confirmer")],1)],1)]),_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('b-form-group',{attrs:{"label-cols":"4","label":"Bénificaire :","label-class":"font-weight-bold"}},[_c('b-form-input',{class:{
                'is-invalid':
                  _vm.$v.newBenf.name.$error && _vm.$v.newBenf.name.$dirty,
              },attrs:{"type":"text","placeholder":"Bénificaire"},model:{value:(_vm.newBenf.name),callback:function ($$v) {_vm.$set(_vm.newBenf, "name", $$v)},expression:"newBenf.name"}}),(_vm.$v.newBenf.name.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newBenf.name.required ? "champ obligatoire" : !_vm.$v.newBenf.name.minLength ? `Le champ doit contenir au moins ${_vm.$v.newBenf.name.$params.minLength.min} caractères.` : "")+" ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Volume :","label-class":"font-weight-bold"}},[_c('b-form-input',{class:{
                'is-invalid':
                  _vm.$v.newBenf.volume.$error && _vm.$v.newBenf.volume.$dirty,
              },attrs:{"type":"number","placeholder":"Volume","min":"0"},model:{value:(_vm.newBenf.volume),callback:function ($$v) {_vm.$set(_vm.newBenf, "volume", $$v)},expression:"newBenf.volume"}}),(_vm.$v.newBenf.volume.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newBenf.volume.required ? "champ obligatoire" : "")+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-12 col-md-6",attrs:{"id":"add-page"}},[_c('b-form-group',{attrs:{"label-cols":"4","label":"Type :","label-class":"font-weight-bold"}},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
                'is-invalid':
                  _vm.$v.newBenf.type.$error && _vm.$v.newBenf.type.$dirty,
              },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticClass:"bg-transparent",attrs:{"placeholder":"Type","options":['Externe', 'Interne']},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.newBenf.type),callback:function ($$v) {_vm.$set(_vm.newBenf, "type", $$v)},expression:"newBenf.type"}})],1),(_vm.$v.newBenf.type.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.newBenf.type.required ? "champ obligatoire" : "")+" ")]):_vm._e()])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }